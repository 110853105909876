import '@angular/compiler';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageMainComponent } from './page.main/page.main.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { ComponentCardComponent } from './component.card/component.card.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageXyzComponent } from './page.xyz/page.xyz.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { LayoutNoneComponent } from './layout.none/layout.none.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageRoundComponent } from './page.round/page.round.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { HighlightDirective } from "src/libs/directives/highlight.directive";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        PortalSeasonStatusbarComponent,
        PortalSeasonLoadingHexaComponent,
        PageMainComponent,
        LayoutNavbarComponent,
        ComponentCardComponent,
        PortalSeasonPagenationComponent,
        PortalSeasonAlertComponent,
        PageXyzComponent,
        PortalSeasonTabComponent,
        PortalSeasonLoadingComponent,
        PortalSeasonUiDropdownComponent,
        LayoutNoneComponent,
        ComponentNavComponent,
        PortalSeasonLoadingSeasonComponent,
        PageRoundComponent,
        PortalSeasonViewerTreeComponent,
        HighlightDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }