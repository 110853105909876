import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav');
import { Input } from '@angular/core';

@Component({
    selector: 'wiz-component-nav',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.nav/view.scss */
body {
  width: 100%;
}`],
})
export class ComponentNavComponent {
  public menuOpen = false; // 초기값: 닫힌 상태

  constructor() {}

  toggle() {
    this.menuOpen = !this.menuOpen; // menuOpen 상태를 전환
  }
}

export default ComponentNavComponent;